import { graphql, Link } from "gatsby"
import moment from "moment"
import React, { Component } from "react"
import { List, Pagination } from "semantic-ui-react"
import Layout from "../../components/layout"
import PilgrimListSearch from "../../components/PilgrimListSearch"
import PrivateRoute from "../../components/privateRoute"
import SEO from "../../components/seo"

class IndexPage extends Component {
  state = {
    perPage: 6,
    activePage: 1,
  }

  handlePaginationChange = (e, { activePage }) => {
    this.setState({ activePage })
  }

  render() {
    const { perPage, activePage } = this.state

    const { walks, headerImage } = this.props.data
    console.log(walks)
    const totalPages = Math.ceil(walks.totalCount / perPage)
    const startWalkIndex = (activePage - 1) * perPage
    const endWalkIndex = startWalkIndex + perPage

    const walkLinkItems = walks.edges
      .slice(startWalkIndex, endWalkIndex)
      .map(({ node }) => (
        <List.Item key={node.id}>
          <List.Header style={{ marginBottom: "0.5em" }}>
            <Link to={`/pilgrim-lists/${node.walkNumber}`}>
              Walk #{node.walkNumber}
            </Link>
          </List.Header>
          <List.Description>
            {moment(node.startDate, "YYYY-MM-DD").format("LL")}
          </List.Description>
        </List.Item>
      ))

    return (
      <Layout
        headerImage={headerImage.childImageSharp}
        headerText="Pilgrim Lists"
        headerSize="sm"
      >
        <SEO title="Pilgrim Lists" keywords={[`pilgrim lists`]} />

        <PilgrimListSearch walks={walks.edges} />

        <List divided relaxed size="large" style={{ padding: "1rem 0" }}>
          {walkLinkItems}
        </List>
        <Pagination
          totalPages={totalPages}
          onPageChange={this.handlePaginationChange}
          activePage={activePage}
          boundaryRange={0}
          ellipsisItem={null}
          // firstItem={{ content: 'Newest' }}
          // lastItem={{ content: 'Oldest' }}
          style={{ maxWidth: "100%" }}
        />
        <p />
      </Layout>
    )
  }
}

export default props => <PrivateRoute component={IndexPage} {...props} />

export const query = graphql`
  query WalksIndex {
    walks: allSanityWalk(sort: { fields: walkNumber, order: DESC }) {
      totalCount
      edges {
        node {
          id
          walkNumber
          startDate
          endDate
        }
      }
    }

    headerImage: file(relativePath: { eq: "candles.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
