import { navigate } from 'gatsby'
import React, { useState } from 'react'
import { Form, Input, Label } from 'semantic-ui-react'

const PilgrimListSearch = ({ walks }) => {
  const [walkNumber, setWalkNumber] = useState('')
  const [error, setError] = useState(false)

  const handleSearchInputChange = ({ target }) => {
    setError(false)
    setWalkNumber(target.value)
  }

  const searchForWalk = () => {
    const [result] = walks.filter(({ node: walk }) => walk.walkNumber === walkNumber)
    if (result) {
      const walk = result.node
      if (walk) {
        navigate(`/pilgrim-lists/${walk.walkNumber}`)
      }
    }

    setError(true)
  }

  return (
    <Form>
      <Form.Field>
        <Input
          placeholder="Find a walk..."
          onChange={handleSearchInputChange}
          value={walkNumber}
          type="number"
          action={{
            type: 'submit',
            color: 'green',
            labelPosition: 'right',
            icon: 'search',
            content: 'Search',
            onClick: () => searchForWalk(walkNumber),
          }}
        />
        <br />
        {error && (
          <Label basic color="orange" pointing>
            Could not find Walk #{walkNumber}
          </Label>
        )}
      </Form.Field>
    </Form>
  )
}

export default PilgrimListSearch
